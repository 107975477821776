import { useErrorBoundary } from 'Logic/ErrorBoundary.js';
import { useFlow, useSetFlowTo } from 'Simple/Flow.js';
import View from './view.js';
import React, { useEffect } from 'react';

export default function Logic(props) {
  let flow = useFlow();
  let setFlowTo = useSetFlowTo(props.viewPath);
  let [error, toggleErrorOff] = useErrorBoundary();

  useEffect(() => {
    if (!error) return;

    if (flow.has('/App/RuntimeError')) {
      toggleErrorOff();
    } else {
      setFlowTo('/App/RuntimeError');
    }
  }, [error, flow]); // eslint-disable-line

  if (error) return null;

  return <View viewPath={props.viewPath} />;
}
