// this is an autogenerated file from App/Authenticate/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Authenticate($access_key: Int!) {
  vaxiom_location_access_keys(
    where: {
      access_key: { _eq: $access_key }
      access_name: { _eq: "PATIENT_CHECKIN" }
    }
  ) {
    id
    access_key
  }
}

`