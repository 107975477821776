// this is an autogenerated file from App/Account/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account(
  $access_key: Int!
  $day_of_birth: numeric!
  $month_of_birth: numeric!
  $last_name_first_letter: String!
) {
  vaxiom_patient_self_checkin(
    access_key: $access_key
    day_of_birth: $day_of_birth
    month_of_birth: $month_of_birth
    last_name_first_letter: $last_name_first_letter
  ) {
    ok
    name
    time
  }
}

`