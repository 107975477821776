import { captureError } from 'Logic/ErrorBoundary.js'
import {
  createClient,
  mapExchange,
  fetchExchange,
  gql,
  Provider,
  subscriptionExchange,
  useClient,
  useMutation,
  useQuery,
  useSubscription,
} from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'
import { devtoolsExchange } from '@urql/devtools'
import { createClient as createSubscriptionClient } from 'graphql-ws'
import { useDataValue } from 'Simple/Data.js'
import { useSetFlowTo } from 'Simple/Flow.js'
import cacheExchangeKeys from './ApiCacheExchangeKeys.js'
import cacheExchangeUpdates from './ApiCacheExchangeUpdates.js'
import React, { useEffect, useMemo, useRef } from 'react'

export { gql, useClient, useMutation, useQuery, useSubscription }

export function viewPathForGraphql(viewPath) {
  return `${process.env.REACT_APP_NAME.replace(/[- ]/g, '_')}__${
    viewPath
      .replace(/\(.+?\)/g, '') // without arguments
      .replace(/\//g, '_') // with _ instead of / because it isn't support by graphql
  }`
}

export function Api(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let dataAuth = useDataValue({ context: 'auth' })
  let auth = useRef(dataAuth)
  // we use this to get new headers without invalidating the current client
  useEffect(() => {
    auth.current = dataAuth
  }, [dataAuth])

  let subscriptionClient = useRef(null)
  let isLoggedIn = !!dataAuth.api_role && dataAuth.api_role !== 'public'
  let client = useMemo(() => {
    if (!isLoggedIn) {
      if (subscriptionClient.current) {
        subscriptionClient.current.dispose()
        subscriptionClient.current = null
      }
      return null
    }

    subscriptionClient.current = createSubscriptionClient({
      url: process.env.REACT_APP_API.replace(/^http/, 'ws'),
      connectionParams: () => ({ headers: getRequestHeaders() }),
    })

    return createClient({
      url: process.env.REACT_APP_API,
      fetch: maybeFetch,
      exchanges: [
        devtoolsExchange,
        cacheExchange({
          keys: cacheExchangeKeys.checkin,
          updates: cacheExchangeUpdates,
        }),
        makeErrorExchange(() => setFlowTo(props.authSignOutView)),
        fetchExchange,
        subscriptionExchange({
          forwardSubscription: operation => ({
            subscribe: sink => ({
              unsubscribe: subscriptionClient.current.subscribe(
                operation,
                sink
              ),
            }),
          }),
        }),
      ],
    })

    function maybeFetch(url, options) {
      // not sure how good of an idea this is but for now, let's ignore public
      // requests sent through the Api component to avoid hitting hasura after a
      // user logged out
      if (auth.current.api_role === 'public') return new Promise(() => {})

      return fetch(url, {
        ...options,
        headers: getRequestHeaders(options.headers),
      })
    }

    function getRequestHeaders(rheaders) {
      let headers = {
        'x-hasura-role': auth.current.api_role,
        ...rheaders,
      }
      if (auth.current.access_token && headers['x-hasura-role'] !== 'public') {
        headers.Authorization = `Bearer ${auth.current.access_token}`
      }
      return headers
    }
  }, [isLoggedIn]) // eslint-disable-line

  // This will likely remount the whole children tree which means that we could
  // see an issue with transitions from auth to the app's content, if we see
  // that we will want to look into merging the PublicApi and this component.
  // For that we'll need to separate the websocket from the rest of the client
  // and update how Auth deals with getting the new token.
  return client ? (
    <Provider value={client}>{props.children}</Provider>
  ) : (
    props.children
  )
}
Api.defaultProps = {
  authSignOutView: '/App/Auth/SignOut',
}

// TODO make the common parts common or make regular fetch calls on
// Data/Auth.js' effect
export function PublicApi(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let client = useMemo(() => {
    return createClient({
      url: process.env.REACT_APP_API,
      fetch: async (url, options) =>
        fetch(url, {
          ...options,
          headers: { 'x-hasura-role': 'public', ...options.headers },
        }),
      exchanges: [
        devtoolsExchange,

        makeErrorExchange(() => setFlowTo(props.authSignOutView)),
        fetchExchange,
      ],
    })
  }, []) // eslint-disable-line

  return <Provider value={client}>{props.children}</Provider>
}
PublicApi.defaultProps = {
  authSignOutView: '/App/Auth/SignOut',
}

function makeErrorExchange(setFlowToAuthSignOut) {
  return mapExchange({
    onError: (error, operation) => {
      if (
        error.graphQLErrors.some(
          item => item.extensions?.code === 'constraint-violation'
        )
      ) {
        // expected business logic exception (should be treated at the component level)
        return
      }
      if (
        // the websocket brings this up
        /unauthorized/i.test(error.message) ||
        /JWTExpired/i.test(error.message) ||
        // http connections do this instead
        error.graphQLErrors.some(
          item =>
            /JWTClaimsSetDecodeError/i.test(item.message) ||
            item.extensions?.code === 'invalid-jwt' ||
            item.extensions?.code === 'access-denied' ||
            item.extensions?.code === 'jwt-invalid-claims' ||
            item.extensions?.code === 'validation-failed'
        )
      ) {
        setFlowToAuthSignOut()
      } else {
        let errorContext = {
          graphQLErrors: error.graphQLErrors,
          networkError: error.networkError,
        }

        try {
          let [app, rviewPath] =
            operation.query.definitions[0].name.value.split('__')

          errorContext = {
            ...errorContext,
            type: operation.kind,
            viewPath: `/${rviewPath.replace(/_/g, '/')}`,
            app,
          }
        } catch (err) {
          errorContext.operation = operation
        }
        captureError(error, errorContext)
      }
    },
  })
}
