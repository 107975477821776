import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'

export default function useDataOnSubmit(props) {
  let onActionCheckin = useDataOnActionCheckin(props)

  return async function onSubmit({ value, args, originalValue, change }) {
    switch (args.type) {
      case 'checkin': {
        return onActionCheckin({ value, originalValue, change })
      }
    }
  }
}

function useDataOnActionCheckin(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onActionCheckin({ value, originalValue, change }) {
    let access_key = sessionStorage.getItem('_greyfinch_access_key')
    if (!access_key) {
      return setFlowTo(normalizePath(props.viewPath, '../Authenticate'))
    }

    if (
      !value.last_name_first_letter ||
      !value.day_of_birth ||
      !value.month_of_birth
    ) {
      setFlowTo(normalizePath(props.viewPath, 'LastNameFirstLetter'))
      return
    }

    let mutationResponse = await executeMutation({
      access_key: parseInt(access_key),
      last_name_first_letter: value.last_name_first_letter,
      day_of_birth: value.day_of_birth,
      month_of_birth: value.month_of_birth,
    })

    if (!mutationResponse.data?.vaxiom_patient_self_checkin.ok) {
      change(originalValue)
      return setFlowTo(normalizePath(props.viewPath, 'GoToFrontDesk'))
    }

    change({
      ...originalValue,
      user_data: mutationResponse.data.vaxiom_patient_self_checkin,
    })
    setFlowTo(normalizePath(props.viewPath, 'CheckedIn'))
  }
}
