import View from './view.js'
import React, { useEffect, useState } from 'react'

export default function Logic(props) {
  let [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let cancel = false
    setTimeout(() => {
      if (cancel) return
      setIsLoading(true)
    }, props.delay)
    return () => {
      cancel = true
      clearTimeout()
    }
  }, [props.delay])

  return isLoading ? <View {...props} /> : null
}
View.defaultProps = {
  delay: 500,
}
