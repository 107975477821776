import { useDataChange } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'checkin',
    path: 'day_of_birth',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick(value) {
    change(value)
    setFlowTo(normalizePath(props.viewPath, '../MonthOfBirth'))
  }
}
