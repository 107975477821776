import 'Logic/polyfillObjectEntries.js'
import '@reach/dialog/styles.css'
import './index.css'
import './version.js'
import { ErrorBoundary } from 'Logic/ErrorBoundary.js'
import { PublicApi } from 'Data/Api.js'
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App/index.js'
import LoadingBar from 'DesignSystem/LoadingBar/index.js'

// not ideal but...
let error = window.console.error
window.console.error = (...args) => {
  if (/(cannot appear as a descendant of|must have either an)/.test(args[0]))
    return

  error(...args)
}

let loading = (
  <div
    style={{
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto',
    }}
    className="views-block"
  >
    <LoadingBar
      borderBottomLeftRadius={10}
      borderBottomRightRadius={10}
      borderTopLeftRadius={10}
      borderTopRightRadius={10}
      height={80}
      width={320}
    />
  </div>
)

let root = createRoot(document.getElementById('root'))

root.render(
  <ErrorBoundary>
    <App>
      {({ children, viewPath }) => (
        <PublicApi viewPath={viewPath} authSignOutView="/App/RuntimeError">
          <ErrorBoundary>
            <Suspense fallback={loading}>{children}</Suspense>
          </ErrorBoundary>
        </PublicApi>
      )}
    </App>
  </ErrorBoundary>
)
