import React, { useEffect } from 'react'
import View from './view.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export default function Logic(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  useEffect(() => {
    let token = sessionStorage.getItem('_greyfinch_access_key')
    setFlowTo(
      normalizePath(props.viewPath, !!token ? '../Account' : '../Authenticate')
    )
  }, []) // eslint-disable-line
  // setFlowTo is not necessary as a useEffect dependency

  return <View {...props} />
}
