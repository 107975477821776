import React, { useRef } from 'react'
import View from './view.js'
import useMaskedInput from '@local/use-masked-input'

let MASKS = {
  sixDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
}

export default function Logic(props) {
  let input = useRef(null)

  if (process.env.REACT_APP_ENV === 'development' && !(props.mask in MASKS)) {
    throw new Error(
      `${props.mask} isn't a valid mask. Choose from ${Object.keys(MASKS)}`
    )
  }

  let onChange = useMaskedInput({
    input,
    ...MASKS[props.mask],
    onChange: event => props.onChange(event.target.value),
    initialValue: props.initialValue,
  })

  return (
    <View
      {...props}
      innerRef={input}
      onChange={onChange}
      onKeyUp={event => {
        if (event.key === 'Enter' && typeof props.onSubmit === 'function') {
          props.onSubmit()
        }
      }}
      onSubmit={props.onSubmit}
    />
  )
}
View.defaultProps = {
  mask: 'sixDigits',
  initialValue: '',
}
