import { useDataSubmit } from 'Simple/Data.js'
import View from './view.js'
import React, { useEffect } from 'react'

export default function Logic(props) {
  let submit = useDataSubmit({
    context: 'checkin',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    submit({ type: 'checkin' })
  }, [])

  return <View viewPath={props.viewPath} />
}
