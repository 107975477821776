import { useClient } from 'Data/Api.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import query from './query.graphql.js'
import React, { useState } from 'react'
import View from './view.js'

export default function Logic(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [value, onChange] = useState('')
  let client = useClient()

  return (
    <View
      onChange={onChange}
      value={value}
      onSubmit={onSubmit}
      isDisabled={value.length < 6}
      viewPath={props.viewPath}
    />
  )

  async function onSubmit() {
    try {
      setFlowTo(normalizePath(props.viewPath, 'Loading'))
      let queryResponse = await client
        .query(
          query,
          { access_key: parseInt(value, 10) },
          {
            fetchOptions: {
              headers: {
                'x-hasura-public-resource-id': value,
              },
            },
          }
        )
        .toPromise()

      if (
        queryResponse.error ||
        queryResponse.data.vaxiom_location_access_keys.length === 0
      ) {
        sessionStorage.removeItem('_greyfinch_access_key')
        return setFlowTo(normalizePath(props.viewPath, 'Invalid'))
      }

      sessionStorage.setItem('_greyfinch_access_key', value)
      setFlowTo(normalizePath(props.viewPath, '../Account'))
    } catch (error) {
      setFlowTo('/App/RuntimeError')
    }
  }
}
